<script>
/**
 * DEPENDENCIES
 */
import {computed, ref} from "vue";
import {useStore} from "vuex";
import {scaleLinear} from "d3-scale";
import {extent} from "d3-array";
import SvgIcon from '@/components/SvgIcon';

/**
 * VUE COMPONENT
 */
export default {
  props : ["indice", "country"],
  setup(props){
    const store = useStore();
    const barWidth     = 20;
    const barMinHeight = 0;
    const barHeight    = 20;
    const textMargin   = 5;
    const barMargin    = 1;
    const barsWidth    = computed( () => ((props.indice.values.length + barMargin) * barWidth));
    const type         = computed( () => props.indice.values[0].type); 
    const domain       = computed( () => type.value == "porcentaje" ? [0, 100] : extent( props.indice.values.map(d => d.value) ));
    const range        = [barMinHeight, barHeight];
    const barScale     = scaleLinear().domain(domain.value).range(range);
    const year         = ref(props.indice.values[0].year);
    const value        = computed( () => props.indice.values.find(d => d.year == year.value).value);
    const text         = computed( () => store.getters.getText(props.country.id, props.indice.name) ); 
    const setYear      = newYear => year.value = newYear;
    return {
      getIcon : store.getters.getIcon,
      barsWidth,
      barScale,
      barWidth,
      barHeight,
      barMargin,
      textMargin,
      year,
      value,
      format : store.getters.formatNumber,
      text,
      setYear
    }
  },

  components : {
    SvgIcon
  }
}
</script>
<template>
  <div class="col-sm-10 offset-sm-1">
    <section class="im_index pt-4">
      <div v-if="text" class="mb-4 pb-4">
        <h3 class="mb-2">{{indice.name}} en <strong>{{country.name}}</strong></h3>
        <p>{{text}}</p>
      </div>
      <div class="row">
        <div class="col-sm-8">
          <h4>
            <svg-icon :icon="getIcon('personas')" :hasFill="true" class="od_svg_icon"></svg-icon>
            {{indice.name}}
          </h4>
        </div>
        <div class="col-sm-4">
          <p class="im_label text-center mb-0">
            Selecciona y da clic sobre un año
          </p>
          <div class="od_smallbars_h">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              :viewBox="`0 0 ${barsWidth} ${barHeight + textMargin}`"
              className="small_bars">
              <g>
                <rect v-for="(rect, i) of indice.values"
                  :width="barWidth"
                  :height="barScale(rect.value)"
                  :transform="`translate(${(barWidth + barMargin) * i},${barHeight - barScale(rect.value)})`"
                  :data-value="rect.value"
                  :data-year="rect.year"
                  :style="`cursor:pointer; fill: ${year == rect.year ? 'rgb(230, 58, 54)' : 'rgb(231, 230, 230)'}`"
                  v-on:click="setYear(rect.year)"
                  :key="`${indice.column}-${rect.year}`"
                ></rect>
                <text v-for="(val, i) of indice.values"
                  text-anchor="middle"
                  alignment-baseline="hanging"
                  :transform="`translate(${((barWidth + barMargin) * i) + + (barWidth / 2)},${barHeight})`"
                  :style="`cursor:pointer; font-size: 0.4em; fill: ${year == val.year ? 'rgb(230, 58, 54)' : 'rgb(231, 230, 230)'}`"
                  v-on:click="setYear(val.year)"
                  :key="`text-${indice.column}-${val.year}`">
                  {{val.year}}
                </text>
            
              </g>
            </svg>
          </div>
        </div>
      </div>
      <div class="row mt-4 pt-4">
        <div class="col-12 text-center">
          <h5 class="im_label">{{indice.format == 'porcentaje' ? 'porcentaje' : 'valor'}} en {{year}}</h5>
          <h3 class="im_amount"><span v-if="indice.name == 'Porcentaje del presupuesto nacional destinado a VIH'">{{value}}</span><span v-else>{{format(value)}}</span>{{indice.format == 'porcentaje' ? '%':''}}</h3>
        </div>
      </div>
      <div class="row mt-4 pt-4">
        <div class="col-12">
          <div>
            <!-- -->
            <div v-if="indice.format == 'porcentaje'">
              <div class="mb-3">
                <span
                  style="
                    display: inline-block;
                    height: 15px;
                    width: 100%;
                    background: rgb(242, 242, 240);">
                  <span :style="{
                      display : 'inline-block',
                      width : `${value}%`,
                      height : '15px',
                      background : 'rgb(233, 176, 59)'
                    }">
                  </span>
                </span>
              </div>
              <p class="mb-0">
                <span
                  style="
                    display: inline-block;
                    height: 10px;
                    background: rgb(233, 176, 59);
                    width: 10px;
                    margin: 0px 5px 0px 0px;
                  "
                ></span>
                {{indice.name}}
              </p>
              <p class="mb-0"><span class="im_stacked_amount"><span v-if="indice.name == 'Porcentaje del presupuesto nacional destinado a VIH'">{{value}}</span><span v-else>{{format(value)}}</span>%</span></p>
            </div>
          </div>
        </div>
      </div>
      <!--source-->
      <div class="row">
        <div class="col-12">
          <p class="text-right od_source">
            Fuentes: <span v-if="indice.name == 'Porcentaje de licitaciones adjudicadas mediante procesos competitivos' || 
                                 indice.name == 'Porcentaje de contratos en los que no se reporta a quién se compra' || 
                                 indice.name == 'Porcentaje de contratos sin información de artículos comprados' ||
                                 indice.name == 'Diferencia porcentual entre el monto de adjudicación del contrato y el monto final del contrato' ||
                                 indice.name == 'Número de días para que los licitadores presenten expresiones de interés o preparen ofertas para procesos competitivos'">                                 
                                 Portal de compras del gobierno</span> <span v-else>ONUSIDA e Informes GAM del país</span>. 
          </p>
        </div>
      </div>
    </section>
  </div>
</template>