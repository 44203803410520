<script>
/**
 * DEPENDENCIES
 */
import {computed} from "vue";
import {useStore} from "vuex";
import SvgIcon from '@/components/SvgIcon';

/**
 * VUE COMPONENT
 */
export default {
  props : ["indice", "country"],
  setup(props){
    const store = useStore();
    const text  = computed( () => store.getters.getText(props.country.id, props.indice.name) ); 
    return {
      getIcon : store.getters.getIcon,
      text
    }
  },

  components : {
    SvgIcon
  }
}
</script>
<template>
  <div class="col-sm-10 offset-sm-1">
    <section class="im_index pt-4">
      <div v-if="text" class="mb-4 pb-4">
        <h3 class="mb-2">{{indice.name}} en <strong>{{country.name}}</strong></h3>
        <p>{{text}}</p>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <h4><svg-icon :icon="getIcon('personas')" :hasFill="true" class="od_svg_icon icon_title"></svg-icon>
          {{indice.name}} </h4>
        </div>
      </div>
      <div class="row mt-4 pt-4">
        <div class="col-12">
            <div class="mb-3">
              <span style="display: inline-block; height: 15px; width: 100%; background: rgb(242, 242, 240);"></span>
              <div class="od_triangle_danger"></div>
            </div>
         </div>
       </div>
       <p class="mb-0"><strong>Sin información disponible</strong></p>
          
    </section>
  </div>
  
</template>