<script>
import {useStore} from "vuex";
import SvgIcon from '@/components/SvgIcon';
export default {
  props : ['country', 'items', 'indices'],
  setup(){
    // console.log(props);
    const store = useStore();
    return {
      getIcon : store.getters.getIcon,
    }
  },
  components : {
    SvgIcon,
  }
}
</script>

<template>
      <div class="col-12 pt-4 mt-4">
        <h2><strong>OBJETIVO 1</strong>: Progreso 90-90-90</h2>
        <div class="table">
        <table class="table objetivos">
          <tbody>
            <tr>
              <td rowspan="2">
                <div class="row mb-4">
                  <div class="col-1">
                    <svg-icon :icon="getIcon('gasto')" :hasFill="true" class="od_svg_icon icon_title"></svg-icon>
                  </div>
                  <div class="col-11">
                    90% de las personas (niños, niñas, adolescentes y adultos) con el VIH conocen su estado.
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-1">
                    <svg-icon :icon="getIcon('gasto')" :hasFill="true" class="od_svg_icon icon_title"></svg-icon>
                  </div>
                  <div class="col-11">
                    90% de las personas con el VIH y que conocen su estado están recibiendo tratamiento.
                  </div>
                </div>
                <div class="row">
                  <div class="col-1">
                    <svg-icon :icon="getIcon('gasto')" :hasFill="true" class="od_svg_icon icon_title"></svg-icon>
                  </div>
                  <div class="col-11">
                    90% de las personas en tratamiento han suprimido la carga viral.
                  </div>
                </div>
               
                
              </td>
              <td>
                <div class="row">
                  <div class="col-1">
                    <svg-icon :icon="getIcon('personas')" :hasFill="true" class="od_svg_icon icon_title"></svg-icon>
                  </div>
                  <div class="col-11">
                  <strong>Compromiso 2 de la Reunión de Alto Nivel (High Level Meeting) 2016-2021 de la ONU</strong><br>
                  Garantizar que los 30 millones de personas con el VIH tienen acceso al tratamiento mediante el cumplimiento de los objetivos 90-90-90 antes de 2020
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="row">
                  <div class="col-1">
                    <svg-icon :icon="getIcon('tratamiento')" :hasFill="true" class="od_svg_icon icon_title"></svg-icon>
                  </div>
                  <div class="col-11">
                  <strong>Objetivo de Desarrollo Sostenible  3</strong><br>
                  Buena salud y bienestar
                </div>
              </div>
              </td>
            </tr>
          </tbody>
        </table>
        </div>
      </div>
</template>