<script>
/**
 * LOAD DEPENDENCIES
 * --------------------------------------------------------------------------------
 */
import {computed} from "vue";
import {useStore} from "vuex";
import PercentGraph from '@/components/PercentGraph';
import EmptyGraph from '@/components/EmptyGraph';
import Objective1 from '@/components/Objective1';

/**
 * VUE COMPONENT
 * --------------------------------------------------------------------------------
 */
export default {
  props : ['country', 'items', 'indices'],
  setup(props){
    const store    = useStore();
    const values   = store.getters.getDataForObjectiveGraphs(props.indices, props.items, 1);
    const textIndi = computed( () => store.getters.getIndicatorText(1,1,props.country.id));
    const all      = store.state.indexGuide.filter(d => d.axis == 1 && d.objective == 1);

    /**
     * TEMPLATE ELEMENTS
     * --------------------------------------------------------------------------------
     **/
    return {
      getIcon : store.getters.getIcon,
      values,
      textIndi,
      all
    }
  },

  /**
   * COMPONENTS
   * --------------------------------------------------------------------------------
   **/
  components : {
    // SvgIcon,
    PercentGraph,
    EmptyGraph,
    Objective1
  }
}
</script>
<template>
  <div class="row pt-4 mt-4">
      <objective-1/>

      <div class="col-sm-10 offset-sm-1 mb-4 pb-4">
        <h3 class="mb-4">Avance en {{country.name}} en el cumplimiento del tercer 90</h3>        
        <div v-html="textIndi"></div>
      </div>

      <div v-for="index of all" :key="`tmp-${index.db_name}`">
        <!-- <percent-graph v-for="indice of values" :indice="indice" :country="country" :key="indice.name" /> -->
        <percent-graph v-if="values.find(d => index.db_name == d.column)" :indice="values.find(d => index.db_name == d.column)" :country="country" />
        <empty-graph v-else :indice="index" :country="country" />
      </div>

    </div>
</template>